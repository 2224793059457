import * as React from "react";
import Layout from "../comps/reusable/Layout";
import { Link, graphql } from "gatsby";
import { useEffect } from "react";
import Seo from "../comps/reusable/Seo";
import { useTranslation } from "gatsby-plugin-react-i18next";
import mainHeroImage from "../images/home/main-hero-image.png";
import {
  CompaniesSection,
  ContactSection,
  CostumerSection,
  HeroSection,
  ImpactSection,
  ResultsSection,
  ServicesSection,
  ServicesSectionInfo,
  SolutionsSection,
  TalentSection,
} from "../styles/pages/home";
import piezas from "../images/home/piezas.png";
import piezaTime from "../images/home/pieza-time.png";
import piezaAts from "../images/home/pieza-ats.png";
import servicesTitleFigure from "../images/home/services-title-figure.png";
import atsImage from "../images/home/ats-image.png";
import headhuntinImage from "../images/home/headhuntin-image.png";
import stars from "../images/home/stars.png";
import calendarClick from "../images/home/calendar-click.png";
import clockClick from "../images/home/clock-click.png";
import desktopClick from "../images/home/desktop-click.png";
import starClick from "../images/home/star-click.png";
import userClick from "../images/home/user-click.png";
import usersClick from "../images/home/users-click.png";
import windowClick from "../images/home/window-click.png";
import userStarClick from "../images/home/user-star-click.png";
import talent from "../images/home/talent.png";
import underlineSubtitle from "../images/home/underline-subtitle.png";
import pieza1 from "../images/home/piezas-1.png";
import pieza2 from "../images/home/piezas-2.png";
import cert from "../images/home/cert-b.png";
import certCarbonNeutral from "../images/logos/CARBON_NEUTRAL_BLACK.png";
import forbes from "../images/home/forbes-30.png";
import figures from "../images/home/figures.png";
import contactFigure1 from "../images/home/contact-figure-1.png";
import contactFigure2 from "../images/home/contact-figure-2.png";
import Carousel from "../comps/reusable/Carousel";
import CarouselTestimonials from "../comps/reusable/CarouselTestimonials";

const dermavanBlack =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2FDermavan%20Negro.png?alt=media&token=824f3023-ea05-41aa-adb8-9979dddf73d9";
const flatBlack =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2FFlat%20Negro.png?alt=media&token=3ac0b29b-c34e-422f-8349-8e38eff2f640";
const leadgeniosBlack =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2FlEADGENIOS%20Negro.png?alt=media&token=92aba62d-ab6c-404f-8c0a-27fca2129dfb";
const echaleBlack =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2FLOGO-E%CC%81chale-09%20Negro.png?alt=media&token=0e146370-0bab-4086-b4c7-4e2719cfaedd";
const walliaBlack =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2FWallia%20Negro.png?alt=media&token=4d6b80e1-db56-4af0-ac3a-f9868de38f9b";

const App = () => {
  const { t } = useTranslation("home");

  // Redirects if user comes from Google integration in ATS
  useEffect(() => {
    const integrationUrl = window.location.href;
    if (window.location.href.includes("www.googleapis.com")) {
      const atsUrl = integrationUrl.replace(
        "https://empresas.lapieza.io/",
        "https://ats.lapieza.io/"
      );
      window.open(atsUrl, "_self");
    }
  }, []);

  return (
    <Layout modeHeader="dark">
      <Seo />
      <HeroSection>
        <div className="hero-section main-landing-container-row">
          <article>
            <h1>{t("heroTitle")}</h1>
            <p>{t("heroDesc")}</p>
            <div className="button-group">
              <a
                className="action-button"
                href="https://calendly.com/alexa-villegas"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("callAcction")}
              </a>
              <a
                className="see-more-button"
                href="https://ats.lapieza.io/authorize-me"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("seeMoreButton")}
              </a>
            </div>
          </article>
          <figure>
            <img src={mainHeroImage} alt="LaPieza" />
          </figure>
        </div>
      </HeroSection>
      <CompaniesSection>
        <div className="companies-section">
          <Carousel
            logos={[
              dermavanBlack,
              flatBlack,
              leadgeniosBlack,
              echaleBlack,
              walliaBlack,
            ]}
          />
        </div>
      </CompaniesSection>
      <TalentSection>
        <div className="main-landing-container-column">
          <h2>{t("talentTitle")}</h2>
          <div className="info-list">
            <article>
              <figure>
                <img src={piezas} alt="Ilustración de LaPieza" />
              </figure>
              <h5>{t("talentItemTitle1")}</h5>
              <p>{t("talentItemDescription1")}</p>
            </article>
            <article>
              <figure>
                <img src={piezaTime} alt="Ilustración de LaPieza" />
              </figure>
              <h5>{t("talentItemTitle2")}</h5>
              <p>{t("talentItemDescription2")}</p>
            </article>
            <article>
              <figure>
                <img src={piezaAts} alt="Ilustración de LaPieza" />
              </figure>
              <h5>{t("talentItemTitle3")}</h5>
              <p>{t("talentItemDescription3")}</p>
            </article>
          </div>
        </div>
      </TalentSection>
      <ServicesSection>
        <div className="main-landing-container-column">
          <h2>{t("servicesTitle")}</h2>
          <h3>
            <span>{t("servicesSubTitle")}</span>
            <figure>
              <img src={servicesTitleFigure} alt="Ilustración de LaPieza" />
            </figure>
          </h3>

          <div className="info-section">
            <article>
              <h4>{t("servicesItemSubtitle1")}</h4>
              <h5>{t("servicesItemTitle1")}</h5>
              <p>{t("servicesItemDescription1")}</p>
              <Link to="/ats">
                {t("seeMoreButton")} {">"}
              </Link>
              <figure>
                <img src={atsImage} alt="ATS LaPieza" />
              </figure>
            </article>
            <article>
              <h4>{t("servicesItemSubtitle2")}</h4>
              <h5>{t("servicesItemTitle2")}</h5>
              <p>{t("servicesItemDescription2")}</p>
              <Link to="/headhunting">
                {t("seeMoreButton")} {">"}
              </Link>
              <figure>
                <img src={headhuntinImage} alt="ATS LaPieza" />
              </figure>
            </article>
          </div>
        </div>
      </ServicesSection>
      <ServicesSectionInfo>
        <div className="main-landing-container-column">
          <h2>
            {t("servicesInfoTitle")}
            <figure>
              <img src={stars} alt="Ilustración de LaPieza" />
            </figure>
          </h2>
          <div className="info-section">
            <div className="info-section-item">
              <h4>{t("servicesInfoAtsTitle")}</h4>
              <div className="info-section-column">
                <article>
                  <img src={userClick} alt="Icon LaPieza" />
                  <div>
                    <h5>{t("servicesInfoAtsItemTitle1")}</h5>
                    <p>{t("servicesInfoAtsItemDescription1")}</p>
                  </div>
                </article>
                <article>
                  <img src={desktopClick} alt="Icon LaPieza" />
                  <div>
                    <h5>{t("servicesInfoAtsItemTitle2")}</h5>
                    <p>{t("servicesInfoAtsItemDescription2")}</p>
                  </div>
                </article>
                <article>
                  <img src={windowClick} alt="Icon LaPieza" />
                  <div>
                    <h5>{t("servicesInfoAtsItemTitle3")}</h5>
                    <p>{t("servicesInfoAtsItemDescription3")}</p>
                  </div>
                </article>
                <article>
                  <img src={usersClick} alt="Icon LaPieza" />
                  <div>
                    <h5>{t("servicesInfoAtsItemTitle4")}</h5>
                    <p>{t("servicesInfoAtsItemDescription4")}</p>
                  </div>
                </article>
              </div>
            </div>
            <div className="info-section-item">
              <h4>{t("servicesInfoHeadHuntingTitle")}</h4>
              <div className="info-section-column">
                <article>
                  <img src={userStarClick} alt="Icon LaPieza" />
                  <div>
                    <h5>{t("servicesInfoHeadHuntingItemTitle1")}</h5>
                    <p>{t("servicesInfoHeadHuntingItemDescription1")}</p>
                  </div>
                </article>
                <article>
                  <img src={clockClick} alt="Icon LaPieza" />
                  <div>
                    <h5>{t("servicesInfoHeadHuntingItemTitle2")}</h5>
                    <p>{t("servicesInfoHeadHuntingItemDescription2")}</p>
                  </div>
                </article>
                <article>
                  <img src={calendarClick} alt="Icon LaPieza" />
                  <div>
                    <h5>{t("servicesInfoHeadHuntingItemTitle3")}</h5>
                    <p>{t("servicesInfoHeadHuntingItemDescription3")}</p>
                  </div>
                </article>
                <article>
                  <img src={starClick} alt="Icon LaPieza" />
                  <div>
                    <h5>{t("servicesInfoHeadHuntingItemTitle4")}</h5>
                    <p>{t("servicesInfoHeadHuntingItemDescription4")}</p>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </ServicesSectionInfo>
      <SolutionsSection>
        <div className="main-landing-container-row">
          <article>
            <h2>{t("solutionsTitle")}</h2>
            <p>{t("solutionsDescription")}</p>
            <a
              href="https://calendly.com/alexa-villegas"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("callAcction")}
            </a>
          </article>
          <figure>
            <img src={talent} alt="Servicios LaPieza" />
          </figure>
        </div>
      </SolutionsSection>
      <ImpactSection>
        <div className="main-landing-container-column">
          <h2>
            {t("impactTitle")}
            <img src={underlineSubtitle} alt="Nos mueve el impacto LaPieza" />
          </h2>
          <p>{t("impactDescription")}</p>
          <div className="impact-section">
            <img className="figure-one" src={pieza1} alt="Servicios LaPieza" />
            <figure className="cert-impact">
              <img className="figure-two" src={cert} alt="Empresa B LaPieza" />
              <img className="figure-carbon" src={certCarbonNeutral} alt="Empresa B LaPieza" />
            </figure>
            <img
              className="figure-three"
              src={pieza2}
              alt="Carbon Neutral LaPieza"
            />
          </div>
          <Link to="/impact">{t("seeMoreButton")}</Link>
        </div>
      </ImpactSection>
      <ResultsSection>
        <div className="main-landing-container-column">
          <article className="main-title">
            <h2>{t("resultsTitle")}</h2>
            <figure>
              <img
                src={forbes}
                alt="Forbes 30 promeas de los negocios 2022 LaPieza"
              />
            </figure>
          </article>
          <div className="info-list">
            <article>
              <h3>{t("resultsItemTitle1")}</h3>
              <p>{t("resultsItemDescription1")}</p>
            </article>
            <article>
              <h3>{t("resultsItemTitle2")}</h3>
              <p>{t("resultsItemDescription2")}</p>
            </article>
            <article>
              <h3>{t("resultsItemTitle3")}</h3>
              <p>{t("resultsItemDescription3")}</p>
            </article>
          </div>
        </div>
      </ResultsSection>
      <CostumerSection>
        <div className="main-landing-container-column">
          <h2>
            {t("costumerTitle")}
            <img src={figures} alt="Ilustración de LaPieza" />
          </h2>
          <CarouselTestimonials />
        </div>
      </CostumerSection>
      <ContactSection>
        <div className="main-landing-container-column">
          <div className="title-contact">
            <img
              className="figure-one"
              src={contactFigure1}
              alt="Ilustración de LaPieza"
            />
            <h2>{t("contactTitle")}</h2>
            <img
              className="figure-two"
              src={contactFigure2}
              alt="Ilustración de LaPieza"
            />
          </div>
          <div className="button-group">
            <a
              className="action-button"
              href="https://calendly.com/alexa-villegas"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("callAcction")}
            </a>
            <Link className="see-more-button" to="/contact">
              {t("teamContact")}
            </Link>
          </div>
        </div>
      </ContactSection>
    </Layout>
  );
};

export default App;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
