import styled from "styled-components";
import { fonts, landingPalette } from "../styles";

export const HeroSection = styled.header`
  background-color: ${landingPalette.blue};
  padding: 7rem 80px 0 80px;

  @media (max-width: 600px) {
    padding: 7rem 32px 0 32px;
  }

  @media (max-width: 425px) {
    padding: 7rem 16px 0 16px;
  }

  .hero-section {
    justify-content: space-between;
    @media (max-width: 980px) {
      flex-direction: column;
    }
  }

  article {
    max-width: 454px;
    width: 100%;

    h1 {
      font-size: 4.2rem;
      line-height: 4.8rem;
      color: white;
      font-family: ${fonts.SofiaProBold};
      margin-bottom: 4rem;
    }

    p {
      font-size: 1.8rem;
      line-height: 2.4rem;
      color: white;
      font-family: ${fonts.SofiaProMedium};
      margin-bottom: 4rem;
    }

    .button-group {
      display: flex;
      grid-gap: 2.2rem;

      @media (max-width: 450px) {
        flex-wrap: wrap;
      }
    }

    button,
    a {
      border: none;
      outline: none;
      border-radius: 21px;
      height: 44px;
      padding: 0 30px;
      font-family: ${fonts.SofiaProMedium};
      font-size: 1.6rem;
      line-height: 2rem;
      margin-bottom: 3.5rem;
      text-decoration: none;
      display: flex;
      align-items: center;

      @media (max-width: 450px) {
        margin-bottom: 1.6rem;
      }
    }

    .action-button {
      color: white;
      background-color: ${landingPalette.orange};
    }

    .see-more-button {
      background-color: white;
      color: ${landingPalette.blue};
    }
  }

  figure {
    max-width: 65rem;
    width: 100%;

    img {
      width: 100%;
      object-fit: scale-down;
      margin-bottom: -2px;
    }
  }
`;

export const CompaniesSection = styled.section`
  padding: 4rem 80px 10rem 80px;

  @media (max-width: 600px) {
    padding: 4rem 32px 10rem 32px;
  }

  @media (max-width: 425px) {
    padding: 4rem 16px 10rem 16px;
  }

  .companies-section {
    max-width: 1280px;
    width: 100%;
    margin: auto;
  }
`;

export const TalentSection = styled.section`
  padding: 0 80px 12rem 80px;

  @media (max-width: 600px) {
    padding: 0 32px 12rem 32px;
  }

  @media (max-width: 425px) {
    padding: 0 16px 12rem 16px;
  }

  h2 {
    font-size: 3.8rem;
    line-height: 4.8rem;
    color: ${landingPalette.blue};
    font-family: ${fonts.SofiaProBold};
    margin-bottom: 12rem;
    max-width: 47rem;
    text-align: center;
    align-self: center;
  }

  .info-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 9rem;

    @media (max-width: 1080px) {
      flex-wrap: wrap;
      justify-content: space-around;
    }

    article {
      display: flex;
      flex-direction: column;

      @media (max-width: 1080px) {
        text-align: center;
      }

      figure {
        max-width: 10rem;
        width: 100%;
        align-self: center;
        margin-bottom: 3.2rem;

        img {
          width: 100%;
          object-fit: scale-down;
        }
      }

      h5 {
        font-size: 1.8rem;
        line-height: 3.2rem;
        color: ${landingPalette.blueTwo};
        font-family: ${fonts.SofiaProSemiBold};
        margin-bottom: 2rem;
      }

      p {
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: ${landingPalette.greyChevron};
        font-family: ${fonts.sofiaProRegular};
      }
    }
  }
`;

export const ServicesSection = styled.section`
  padding: 0 80px 10rem 80px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 600px) {
    padding: 0 32px 10rem 32px;
  }

  @media (max-width: 425px) {
    padding: 0 16px 10rem 16px;
  }

  h2 {
    font-size: 1.6rem;
    line-height: 2.9rem;
    color: ${landingPalette.orange};
    font-family: ${fonts.SofiaProMedium};
    margin-bottom: 1rem;
  }

  h3 {
    font-size: 4.2rem;
    line-height: 4.8rem;
    color: ${landingPalette.blue};
    font-family: ${fonts.SofiaProBold};
    max-width: 55.7rem;
    position: relative;
    text-align: center;
    margin-bottom: 9rem;

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    span {
      display: inline;
    }

    figure {
      position: absolute;
      max-width: 45px;
      width: 100%;
      bottom: -1rem;
      right: 10%;

      @media (max-width: 768px) {
        position: relative;
        bottom: auto;
        right: auto;
      }

      img {
        width: 100%;
        object-fit: scale-down;
      }
    }
  }

  .info-section {
    display: flex;
    grid-gap: 15px;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 768px) {
      flex-wrap: wrap;
      justify-content: space-around;
    }

    article {
      padding: 5rem 3rem 3rem 3rem;
      width: 100%;
      background-color: ${landingPalette.bgBlue};
      border-radius: 9px;
      display: flex;
      flex-direction: column;
      position: relative;

      h4 {
        font-size: 1.6rem;
        line-height: 2.9rem;
        color: ${landingPalette.orange};
        font-family: ${fonts.SofiaProMedium};
        margin-bottom: 1rem;
      }

      h5 {
        font-size: 2.8rem;
        line-height: 3.5rem;
        color: ${landingPalette.blue};
        font-family: ${fonts.SofiaProSemiBold};
        max-width: 41rem;
        margin-bottom: 2.5rem;
      }

      p {
        font-size: 1.5rem;
        line-height: 2.2rem;
        color: ${landingPalette.greyChevron};
        font-family: ${fonts.SofiaProMedium};
        max-width: 34rem;
        width: 100%;
        margin-bottom: 7.5rem;
      }

      a {
        text-decoration: none;
        font-size: 1.8rem;
        line-height: 3.4rem;
        color: ${landingPalette.green};
        font-family: ${fonts.SofiaProSemiBold};
        margin-top: auto;
        position: relative;
        z-index: 1;
      }

      figure {
        position: absolute;
        bottom: 0;
        right: 0;

        img {
          object-fit: scale-down;
        }
      }
    }
  }
`;

export const ServicesSectionInfo = styled.section`
  padding: 5rem 80px 13rem 80px;
  position: relative;
  z-index: 1;

  @media (max-width: 600px) {
    padding: 5rem 32px 13rem 32px;
  }

  @media (max-width: 425px) {
    padding: 5rem 16px 13rem 16px;
  }

  ::before {
    background-color: ${landingPalette.yellow};
    z-index: 0;
    content: " ";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.07;
  }

  h2 {
    font-size: 3.8rem;
    line-height: 4.8rem;
    color: ${landingPalette.blue};
    font-family: ${fonts.SofiaProBold};
    margin-bottom: 12rem;
    text-align: center;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
      flex-direction: column-reverse;
      margin-bottom: 6rem;
    }

    figure {
      margin-left: 3.2rem;
      max-width: 50px;
      width: 100%;

      img {
        width: 100%;
        object-fit: scale-down;
      }
    }
  }

  .info-section {
    display: flex;
    justify-content: space-between;
    grid-gap: 4rem;
    width: 100%;
    position: relative;
    z-index: 2;

    @media (max-width: 768px) {
      flex-wrap: wrap;
      justify-content: space-around;
    }
  }

  .info-section-item {
    width: 100%;

    h4 {
      font-size: 1.6rem;
      line-height: 2.9rem;
      color: ${landingPalette.orange};
      font-family: ${fonts.SofiaProMedium};
      margin-bottom: 3rem;
      text-align: center;
    }

    .info-section-column {
      display: grid;
      grid-gap: 22px;
    }

    article {
      padding: 2rem 3rem;
      background-color: white;
      border-radius: 8px;
      box-shadow: ${landingPalette.boxShadoPalette};
      display: grid;
      grid-template-columns: auto 1fr;
      grid-gap: 2rem;

      img {
        max-width: 4rem;
        width: 100%;
        object-fit: scale-down;
        margin: auto;
      }

      > div {
        h5 {
          font-size: 1.8rem;
          line-height: 2.2rem;
          color: ${landingPalette.blueText};
          font-family: ${fonts.SofiaProSemiBold};
          margin-bottom: 1.2rem;
        }

        p {
          font-size: 1.5rem;
          line-height: 2.2rem;
          color: ${landingPalette.blueText};
          font-family: ${fonts.sofiaProRegular};
        }
      }
    }
  }
`;

export const SolutionsSection = styled.section`
  padding: 6rem 80px 6rem 80px;
  background-color: ${landingPalette.bgBlue};

  @media (max-width: 600px) {
    padding: 6rem 32px 6rem 32px;
  }

  @media (max-width: 425px) {
    padding: 6rem 16px 6rem 16px;
  }

  .main-landing-container-row {
    justify-content: space-between;
    grid-gap: 5rem;

    @media (max-width: 768px) {
      flex-direction: column-reverse;
      align-items: center;
    }

    figure {
      margin: auto;
    }
  }

  article {
    max-width: 527px;
    width: 100%;

    h2 {
      font-size: 3.8rem;
      line-height: 4.8rem;
      color: ${landingPalette.blue};
      font-family: ${fonts.SofiaProBold};
      margin-bottom: 3rem;
    }

    p {
      font-size: 1.8rem;
      line-height: 2.8rem;
      color: ${landingPalette.blue};
      font-family: ${fonts.SofiaProMedium};
      margin-bottom: 3rem;
    }

    button,
    a {
      border: none;
      outline: none;
      background-color: ${landingPalette.orange};
      border-radius: 21px;
      height: 44px;
      padding: 0 30px;
      font-family: ${fonts.SofiaProMedium};
      font-size: 1.6rem;
      color: white;
      line-height: 2rem;
      text-decoration: none;
      display: inline-flex;
      align-items: center;
    }
  }

  figure {
    max-width: 33rem;
    width: 100%;

    img {
      width: 100%;
      object-fit: scale-down;
    }
  }
`;

export const ImpactSection = styled.section`
  padding: 12rem 80px 14rem 80px;

  @media (max-width: 600px) {
    padding: 12rem 32px 14rem 32px;
  }

  @media (max-width: 425px) {
    padding: 12rem 16px 14rem 16px;
  }

  h2 {
    font-size: 3.8rem;
    line-height: 4.8rem;
    color: ${landingPalette.blue};
    font-family: ${fonts.SofiaProBold};
    margin-bottom: 3rem;
    text-align: center;
    position: relative;

    img {
      position: absolute;
      max-width: 14rem;
      right: 0;
      bottom: -8px;
    }
  }

  p {
    font-size: 1.8rem;
    line-height: 2.8rem;
    color: ${landingPalette.blue};
    font-family: ${fonts.SofiaProMedium};
    margin-bottom: 5rem;
    max-width: 46rem;
    text-align: center;
  }

  .cert-impact {
    display: flex;
    gap: 70px;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
      gap: 32px;
    }

    @media (max-width: 420px) {
      flex-wrap: wrap;
    }
  }

  .impact-section {
    display: flex;
    justify-content: space-between;
    grid-gap: 2rem;
    align-items: center;
    width: 100%;
    margin-bottom: 8rem;

    @media (max-width: 1080px) {
      flex-direction: column;
      flex-wrap: wrap;
      align-content: center;
      grid-gap: 8rem;

      img {
        align-self: auto;
      }
    }
  }

  .figure-one {
    max-width: 26rem;
    width: 100%;
    object-fit: scale-down;
    align-self: flex-end;
  }

  .figure-two {
    max-width: 16rem;
    width: 100%;
    object-fit: scale-down;
    align-self: flex-end;
  }

  .figure-carbon {
    max-width: 16rem;
    width: 100%;
    object-fit: scale-down;
  }

  .figure-three {
    max-width: 26rem;
    width: 100%;
    object-fit: scale-down;
    align-self: flex-end;
  }

  button,
  a {
    border: none;
    outline: none;
    background-color: ${landingPalette.orange};
    border-radius: 21px;
    height: 44px;
    padding: 0 50px;
    font-family: ${fonts.SofiaProMedium};
    font-size: 1.6rem;
    color: white;
    line-height: 2rem;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
  }
`;

export const ResultsSection = styled.section`
  padding: 0 80px 15rem 80px;

  @media (max-width: 600px) {
    padding: 0 32px 15rem 32px;
  }

  @media (max-width: 425px) {
    padding: 0 16px 15rem 16px;
  }

  .main-landing-container-column {
    align-items: normal;
  }

  .main-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 5rem;
    margin-bottom: 9rem;

    @media (max-width: 1080px) {
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 12rem;
      text-align: center;
    }

    h2 {
      font-size: 3.8rem;
      line-height: 4.8rem;
      color: ${landingPalette.blue};
      font-family: ${fonts.SofiaProBold};
      margin-bottom: 3rem;
    }
  }

  .info-list {
    display: flex;
    justify-content: space-between;
    grid-gap: 5rem;

    @media (max-width: 1080px) {
      flex-wrap: wrap;
      justify-content: space-around;
      grid-gap: 10rem;
    }

    article {
      h3 {
        font-size: 6.5rem;
        line-height: 3.9rem;
        color: ${landingPalette.blue};
        font-family: ${fonts.SofiaProBold};
        margin-bottom: 2rem;
      }

      p {
        font-size: 2.4rem;
        line-height: 3.8rem;
        color: ${landingPalette.blue};
        font-family: ${fonts.SofiaProMedium};
      }

      @media (max-width: 1080px) {
        text-align: center;
      }
    }
  }
`;

export const CostumerSection = styled.section`
  padding: 5rem 80px 3.5rem 80px;
  background-color: ${landingPalette.blueThree};

  @media (max-width: 600px) {
    padding: 5rem 32px 3.5rem 32px;
  }

  @media (max-width: 435px) {
    padding: 5rem 16px 3.5rem 16px;
  }

  h2 {
    font-size: 3.8rem;
    line-height: 4.8rem;
    color: white;
    font-family: ${fonts.SofiaProBold};
    margin-bottom: 5rem;
    display: flex;
    align-content: center;

    img {
      max-width: 68px;
      width: 100%;
      margin-top: -20px;
      object-fit: scale-down;
    }
  }
`;

export const ContactSection = styled.section`
  padding: 17rem 80px 11rem 80px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 600px) {
    padding: 17rem 32px 11rem 32px;
  }

  @media (max-width: 425px) {
    padding: 17rem 16px 11rem 16px;
  }

  .title-contact {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10rem;

    @media (max-width: 1080px) {
      justify-content: center;
      img {
        position: absolute;
        z-index: 0;
      }

      .figure-one {
        left: 8%;
      }

      .figure-two {
        right: 8%;
      }
    }
  }

  h2 {
    font-size: 3.8rem;
    line-height: 4.8rem;
    color: ${landingPalette.blue};
    font-family: ${fonts.SofiaProBold};
    max-width: 621px;
    width: 100%;
    text-align: center;
    position: relative;
    z-index: 1;
  }

  .button-group {
    display: flex;
    grid-gap: 2.2rem;

    @media (max-width: 768px) {
      flex-direction: column;
      grid-gap: 0;
    }
  }

  button,
  a {
    border: none;
    outline: none;
    border-radius: 21px;
    height: 44px;
    padding: 0 30px;
    font-family: ${fonts.SofiaProMedium};
    font-size: 1.6rem;
    line-height: 2rem;
    margin-bottom: 3.5rem;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .action-button {
    color: white;
    background-color: ${landingPalette.orange};
  }

  .see-more-button {
    background-color: ${landingPalette.bgBlue};
    color: ${landingPalette.blue};
  }
`;
